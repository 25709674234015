import React from 'react'

const Navbar = () => {
    return (

        <nav className="bg-[#459D47] lg:px-[32px] md:h-[54px] h-[48px] lg:h-[84px]">
            <div className="flex items-center justify-between  mx-auto lg:py-5 p-2 h-full w-full">
                <a href="/" className="flex items-center h-full">
                    <img src={require('../../assets/images/logo-var1.png')} className="w-full h-full" alt="Flowbite Logo" />
                </a>

                <div className='flex flex-row gap-4  h-full' >
                    <a href='#appstore-slider' className='flex-row flex md:rounded-2xl rounded-full bg-orange bg-white md:p-3   md:m-0 h-full'>
                        <img src={require('../../assets/images/apple-transparent-icon.png')} className='md:mr-2' alt='' />
                        <p className=' text-[#459D47] self-center font-bold font-sans sm:block hidden ' >IOS İçin İndir</p>
                    </a>
                    <a href='#google-slider' className='flex-row flex md:rounded-2xl rounded-full bg-orange bg-white md:p-3 mr-3 lg:m-0 '>
                        <img src={require('../../assets/images/android-transparent-icon.png')} className='md:mr-2' alt='' />
                        <p className=' text-[#459D47] self-center font-bold font-sans hidden sm:block ' >ANDROID İçin İndir</p>
                    </a>
                </div>
            </div>
        </nav>

    )
}

export default Navbar