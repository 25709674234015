import { useState, useEffect } from 'react'

// import toast, { Toaster } from 'react-hot-toast';
import { getWebsiteMetadata } from '../services/websiteService';

import Navbar from '../components/user/Navbar'
import Slider from '../components/user/Slider'
import NotificationComp from '../components/user/Notification';
import appleIcon from '../assets/images/apple-transparent-icon.png'
import playstoreIcon from '../assets/images/android-transparent-icon.png'
import androidVideo from '../assets/videos/android.mp4'
import ios from '../assets/videos/ios.mp4'

const Home = () => {

    const [websiteInfo, setWebsiteInfo] = useState({})


    const [activeTab, setActiveTab] = useState('android');
    const [videoSource, setVideoSource] = useState(androidVideo)





    const handleWebsiteMetadata = async () => {
        const data = await getWebsiteMetadata()
        if (data) {
            setWebsiteInfo(data)
        }
    }

    useEffect(() => {
        handleWebsiteMetadata()
    }, [])

    const handler = (e) => {
        setActiveTab(activeTab === 'android' ? 'ios' : 'android')
        setVideoSource(videoSource === androidVideo ? ios : androidVideo)
    }





    return (
        <div className='overflow-hidden' >
            <Navbar />
            {document.referrer.startsWith("android-app://") || navigator.standalone || window.matchMedia("(display-mode: standalone)").matches ?
                <NotificationComp websiteInfo={websiteInfo} /> : <></>
            }
            <section className='flex flex-col items-center ' id='section-1'></section>

            <section className='flex flex-col items-center justify-center bg-white sm:px-3' id='section-2'>
                <div className='lg:mb-8 mt-16' >
                    <h1 className='lg:text-6xl text-3xl text-center  lg:mb-8  from-[#024F00] to-[#36D033] bg-gradient-to-r bg-clip-text text-transparent' >HEMEN ÜYE OLUN <br />PROMOSYONLARI KAÇIRMAYIN!</h1>
                    <p className='lg:text-2xl mt-5 text-center' >Yeni nesil oyunlar, canlı spor karşılaşmaları ve yüksek oranlı bahis seçenekleriyle bahis dünyasına adım atmak için <b>Pinbahis'e katıl!</b> Özel <br /> bonuslar, ödüllü yarışmalar ve daha birçok sürpriz seni bekliyor. Hemen üye ol, bonuslara göz at ve bahis keyfini doyasıya yaşa! Ayrıca,<br /> uygulamayı cihazına indirerek her an her yerde bahis yapmanın keyfini çıkarabilirsin.</p>
                </div>
                <div className='relative  mb-3' >
                    <img src={require('../assets/images/section-2-img-1.png')} className='' alt='section two' />
                </div>

            </section>

            <section className='bg-white lg:mb-10'>
                <div className='p-4 lg:px-32' >
                    <div className='grid lg:grid-cols-4 grid-cols-2 bg-[#DBDBDB] gap-3 lg:p-16 p-5 rounded-3xl '>

                        <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
                            <div className='flex items-center justify-center mb-5' >
                                <img src={require('../assets/images/Rectangle 18118.png')} alt='rectengle' />
                            </div>
                            <h3 className='text-black font-bold text-center mb-5 sm:text-2xl' >Oyunlar</h3>
                            <p className='text-black text-center'>Heyecan dolu oyun ve slotlar ile Pinbahis’te şansınızı denemek için şimdi katılın!</p>
                        </div>

                        <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
                            <div className='flex items-center justify-center mb-5' >
                                <img src={require('../assets/images/Rectangle 18116.png')} alt='musabaka' />
                            </div>
                            <h3 className='text-black font-bold text-center mb-5  sm:text-2xl' >Spor <br />Müsabakaları</h3>
                            <p className='text-black text-center '>Sadece Pinbahislilere özel yüksek oranlar, binlerce müsabaka ve canlı bahis seçeneği seni bekliyor!</p>
                        </div>

                        <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
                            <div className='flex items-center justify-center mb-5' >
                                <img src={require('../assets/images/Rectangle 18119.png')} alt='canli casino' />
                            </div>
                            <h3 className='text-black font-bold text-center mb-5  sm:text-2xl' >Canlı Casino</h3>
                            <p className='text-black text-center'>Kazanmanın keyfini Türkiye’nin en güvenli bahis sitesinde canlı casino seçeneğiyle çıkarın!</p>
                        </div>

                        <div className='lg:max-w-[213px] max-w-[150px] m-auto' >
                            <div className='flex items-center justify-center mb-5' >
                                <img src={require('../assets/images/Rectangle 18117.png')} alt='promosyon' />
                            </div>
                            <h3 className='text-black font-bold text-center mb-5  sm:text-2xl' >Ödül ve <br /> Promosyonlar</h3>
                            <p className='text-black text-center'>Pinbahis’te seni sürprizler ve ödüller bekliyor. Onlarca ödül havuzu ve yarışma ile kazancınızı katlayın!</p>
                        </div>
                    </div>
                </div>

            </section>

            <section className=' relative overflow-hidden relative bg-[#459D47]' id='google-slider'>
                <div className='lg:mb-10 mb-5 px-5 mt-12' >
                    <h3 className='lg:text-5xl text-3xl text-center font-semibold text-white' >PİNBAHİS UYGULAMASI NASIL İNDİRİLİR?</h3>
                    <p className='lg:text-2xl text-1xl text-center  text-white mt-6'>Aşağıdaki adımları takip edin ve Pinbahis uygulamasını telefonunuza indirin.</p>
                </div>
                <div className=' md:px-[44%] mb-9'>
                    <div className='flex-row flex rounded-full bg-[#FFBD3C] p-3  justify-center items-center  '>
                        <img src={require('../assets/images/android.png')} className='mr-2' alt='android' />
                        <p className=' text-white self-center font-bold font-sans lg:text-2xl uppercase' >Android</p>
                    </div>
                </div>
                <div className='lg:px-36 px-5 ' >
                    <Slider platform='android' />
                </div>
            </section>

            <section className='lg:mb-24 mb-10 overflow-hidden relative  bg-[#459D47]' id='appstore-slider'>
                <div className='lg:mb-10 mb-5 px-5 mt-11' >
                    <div className=' md:px-[45%] mb-9'>
                        <div className='flex-row flex rounded-full bg-[#FFBD3C] p-3  justify-center items-center  '>
                            <img src={require('../assets/images/apple.png')} className='mr-2' alt='apple' />
                            <p className=' text-white self-center font-bold font-sans lg:text-2xl uppercase' >APPLE</p>
                        </div>
                    </div>
                </div>
                <div className='lg:px-36 px-5 mb-20' >
                    <Slider platform='ios' />
                </div>
            </section>

            <section className='bg-white flex flex-col' >
                <h1 className='lg:text-6xl text-3xl text-center  lg:mb-16  from-[#024F00] to-[#36D033] bg-gradient-to-r bg-clip-text text-transparent' >VİDEOYU İZLEYİN VE İNDİRİN</h1>

                <div className='m-auto flex gap-5 p-1 rounded-full border-2 bg-black mb-10' style={{ borderColor: 'black' }}>

                    <div
                        className={`flex justify-center p-3 rounded-full ${activeTab === 'android' ? 'bg-white hover:bg-white' : ''}`}
                        onClick={handler}>
                        <img
                            src={playstoreIcon}
                            className='mr-3 text-white'
                            alt='android icon'
                        />
                        <p className='uppercase text-[#45D242] self-center' >android</p>
                    </div>


                    <div
                        className={`flex justify-center p-3 rounded-full ${activeTab === 'ios' ? 'bg-white hover:bg-white ' : ''}`}
                        onClick={handler} >
                        <img
                            src={appleIcon}
                            className='mr-3'
                            alt='apple icon'
                        />
                        <p className='uppercase text-[#45D242] self-center' >APPLE</p>
                    </div>
                </div>

                <div className='mb-6 px-5' >
                    <Player source={videoSource} key={activeTab} />
                </div>

            </section>

            <footer className='bg-[#171717] h-auto mt-10' >
                <div className='sm:grid grid-cols-2'>

                    <div className='self-center'>
                        <p className='md:text-left text-white md:ml-[20%] text-center sm:pt-0  pt-8 '>
                            Türkiye’nin en güvenli alt yapısı ile bahis keyfini yaşayın! Pinbahis,<br /> bahis severlere güvenli bir alt yapı  ve yüzlerce seçenek sunuyor. Spor <br /> bahisleri, slotlar, canlı bahis ve canlı casino, ve en güncel oyunlarıyla <br /> kolay erişilebilir bahis keyfi sizi bekliyor! <br /><br /> Üstelik ödüllü yarışmalar ve birbirinden özel promosyon fırsatları da <br />Pinbahis’te sizi bekliyor!  Kusursuz kullanıcı deneyimi ve güvenilirlik <br /> ilkesiyle donatılmış Pinbahis’te, hızlı ve kolay bir şekilde bahislerinizi <br /> yapabilir, kazançlarınızı anında çekebilirsiniz. <br /><br />Şimdi kazancı zirveye PİN’leme vakti!
                        </p>
                    </div>
                    <div className='m-auto'>
                        <img src={require('../assets/images/section-6-img-1.png')} className='' alt='' />
                    </div>
                </div>

                <div className='md:ml-[10%] pb-4 grid grid-cols-3'>
                    <img src={require('../assets/images/Groups.png')} className='' alt='' />

                    <div className='flex flex-cols gap-5 mb-5 m-auto '>
                        <img src={require('../assets/images/Group13.png')} className='' alt='' />
                        <img src={require('../assets/images/Group14.png')} className='' alt='' />
                        <img src={require('../assets/images/Group15.png')} className='' alt='' />
                        <p className='text-[#DDDDDD] self-center'>© 2023 PİNBAHİS</p>
                    </div>

                </div>
            </footer>

        </div>
    )
}

export default Home

const Player = ({ key, source }) => {
    return (
        <video key={key} className="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
            <source src={source} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}